<template>
  <div class="card">
    <div class="card-header">Ordenação</div>
    <div class="card-body">

      <div class="row align-items-center">

        <div class="col">
          <b-form-group
            label="Campo"
          >
            <model-list-select
              :list="fieldOptions"
              option-value="field"
              option-text="label"
              v-model="selectedFieldOption"
            />
          </b-form-group>
        </div>

        <div class="col-auto">
          <label>Direção:</label>

          <b-form-radio-group v-model="selectDirectionOption" class="mb-3">
            <b-form-radio v-for="option in directionOptions"
              :key="'selectDirectionOption_' + option.value" name="directionOptions" :value="option.value">
              {{option.label}}
            </b-form-radio>
          </b-form-radio-group>
        </div>

        <div class="col-auto">
          <button type="button" class="btn btn-success mt-2" @click.prevent="addRow">
            <i class="fa fa-plus"></i> Adicionar
          </button>
        </div>

      </div>

      <div v-if="config && config.printConfig && config.printConfig.separatedLots && config.printConfig.separatedLots.orderBy">
        <draggable v-model="config.printConfig.separatedLots.orderBy" group="config.printConfig.separatedLots.orderBy" @end="onRowDrop">
          <div v-for="(orderBy, index) in config.printConfig.separatedLots.orderBy"
            :key="'config.printConfig.separatedLots.orderBy_' + index" class="row border py-3 mx-0 cursor-grab" :class="{ 'bg-light': index % 2 == 0 }">

            <div class="col">
              <span class="font-weight-bold">Campo: </span> {{orderBy.condition ? getFieldLabel(orderBy.condition.field) : ''}}
            </div>

            <div class="col">
              <span class="font-weight-bold">Direção: </span> {{getDirectionLabel(orderBy.direction)}}
            </div>

            <div class="col-auto">
              <button type="button" class="btn btn-danger btn-sm" @click.prevent="openDeleteModal(index)">
                <i class="fa fa-trash"></i> Remover
              </button>
            </div>

          </div>
        </draggable>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-auto">
          Nenhum campo de ordenação adicionado
        </div>
      </div>

      <confirmation-modal
        id="separated-lots-order-by-delete-confirmation-modal"
        msg="Tem certeza de que deseja remover esse campo?"
        :ok="deleteItem"
      ></confirmation-modal>

    </div>

  </div>
</template>

<script>
import shared from '@/shared/shared'
import { ModelListSelect } from 'vue-search-select'
import draggable from 'vuedraggable'
import ConfirmationModal from '@/components/common/confirmation-modal'

export default {
  name: 'separated-lots-order-by',
  components: { ModelListSelect, draggable, ConfirmationModal },

  props: {
    config: {
      type: Object,
      require
    },
    updateConfig: {
      type: Function,
      require
    }
  },

  data() {
    return {
      fieldOptions: shared.getPrintSeparatedLotsConfigOrderFieldOptions(),
      selectedFieldOption: {},
      selectDirectionOption: {},
      directionOptions: [
        { value: 'ASC', label: 'Ascendente' },
        { value: 'DESC', label: 'Descendente' }
      ],
      deleteIndex: -1
    }
  },

  mounted() {
    this.selectDirectionOption = 'ASC';
    this.resetForm();
  },

  methods: {
    resetForm() {
      this.selectedFieldOption = {};
    },

    getFieldLabel(field) {
      if (field) {
        let temp = this.fieldOptions.find(fo => fo.field === field);

        if (temp) {
          return temp.label;
        }
      }

      return '';
    },

    getDirectionLabel(direction) {
      if (direction) {
        let temp = this.directionOptions.find(fo => fo.value === direction);

        if (temp) {
          return temp.label;
        }
      }

      return '';
    },

    onRowDrop() {
      this.updateConfig(this.config);
    },

    addRow() {
      if (!this.selectedFieldOption || !this.selectedFieldOption.field) {
        this.$notify.textError('Selecione o campo');
        return;
      }

      if (!this.selectDirectionOption) {
        this.$notify.textError('Selecione a direção');
        return;
      }

      if (!this.config.printConfig.separatedLots.orderBy) {
        this.config.printConfig.separatedLots.orderBy = [];
      }

      if (this.config.printConfig.separatedLots.orderBy.find(o => {
        return o.condition && o.condition.field === this.selectedFieldOption.field;
      })) {
        this.$notify.textError('Esse campo já foi adicionado');
        return;
      }

      this.config.printConfig.separatedLots.orderBy.push({
        condition: {
          field: this.selectedFieldOption.field
        },
        direction: this.selectDirectionOption
      });
      this.$notify.success('Campo adicionado com sucesso');
      this.resetForm();
      this.updateConfig(this.config);
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show('separated-lots-order-by-delete-confirmation-modal');
    },

    deleteItem() {
      this.$notify.success('Campo removido com sucesso');
      this.config.printConfig.separatedLots.orderBy.splice(this.deleteIndex, 1);
      this.deleteIndex = -1;
      this.updateConfig(this.config);
    },
  }
}
</script>
