<template>
  <div class="animated fadeIn">
    <div v-if="config">
      <div v-if="config.printConfig">
        <div class="card">
          <div class="card-header">Configurações de Impressão</div>
          <div class="card-body">
            <div class="row mx-0">

              <div class="col-md-6 col-12">
                <div class="card">
                  <div class="card-header">Configurações das etiquetas dos lotes</div>
                  <div class="card-body">
                    <div class="mx-3">
                      <b-form-group
                        label="Modelo de etiqueta dos lotes"
                      >
                        <model-list-select
                          :list="lotPrintModels"
                          option-value="value"
                          option-text="label"
                          v-model="config.printConfig.model"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="card">
                  <div class="card-header">Configurações das etiquetas dos endereços</div>
                  <div class="card-body">
                    <div class="mx-3">
                      <b-form-group
                        label="Modelo de etiqueta dos endereços"
                      >
                        <model-list-select
                          :list="locationPrintModels"
                          option-value="value"
                          option-text="label"
                          v-model="config.printConfig.locationModel"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card">
                  <div class="card-header">Configurações de impressão do modelo padrão das etiquetas</div>
                  <div class="card-body">
                    <div class="row mx-0">
                      <div class="col-xl-6 col-12">
                        <div class="row">

                          <div class="col-12">
                            <div class="card">
                              <div class="card-header">Etiqueta</div>
                              <div class="card-body">
                                <div class="row mx-0">
                                  <div class="col-6">
                                    <b-form-group
                                      label="Largura (mm):"
                                      label-for="imageWidth"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="imageWidth"
                                        v-model="config.printConfig.width"
                                        @change="imageWidthChanged"
                                      />
                                    </b-form-group>
                                  </div>
                                  <div class="col-6">
                                    <b-form-group
                                      label="Altura (mm):"
                                      label-for="imageHeight"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="imageHeight"
                                        v-model="config.printConfig.height"
                                        @change="imageHeightChanged"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>
                                <div class="row mx-0 align-items-end mt-3">
                                  <div class="col-6">
                                    <b-form-group
                                      label="Margem esquerda (mm):"
                                      label-for="marginLeft"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="marginLeft"
                                        v-model="config.printConfig.marginLeft"
                                      />
                                    </b-form-group>
                                  </div>
                                  <div class="col-6">
                                    <b-form-group
                                      label="Margem direita (mm):"
                                      label-for="marginRight"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="marginRight"
                                        v-model="config.printConfig.marginRight"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>
                                <div class="row mx-0 align-items-end mt-3">
                                  <div class="col-6">
                                    <b-form-group
                                      label="Margem superior (mm):"
                                      label-for="marginTop"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="marginTop"
                                        v-model="config.printConfig.marginTop"
                                      />
                                    </b-form-group>
                                  </div>
                                  <div class="col-6">
                                    <b-form-group
                                      label="Margem inferior (mm):"
                                      label-for="marginBottom"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="marginBottom"
                                        v-model="config.printConfig.marginBottom"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="card">
                              <div class="card-header">Código de Barras</div>
                              <div class="card-body">
                                <div class="row mx-0">
                                  <div class="col">
                                    <b-form-group
                                      label="Altura (%):"
                                      label-for="barcodeHeightPercentage"
                                      class="mb-0"
                                      description="Porcentagem da altura da etiqueta (menos margem superior e inferior) que será ocupada pelo código de barras"
                                    >
                                      <b-form-input
                                        id="barcodeHeightPercentage"
                                        v-model="config.printConfig.barcodeHeightPercentage"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-xl-6 col-12">
                        <div class="row">

                          <div class="col-12">
                            <div class="card">
                              <div class="card-header">Tamanho da Página de Impressão</div>
                              <div class="card-body">
                                <div class="row mx-0">
                                  <div class="col">
                                    <b-form-group
                                      label="Largura (mm):"
                                      label-for="pageWidth"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="pageWidth"
                                        v-model="config.printConfig.pageWidth"
                                      />
                                    </b-form-group>
                                  </div>
                                  <div class="col">
                                    <b-form-group
                                      label="Altura (mm):"
                                      label-for="pageHeight"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="pageHeight"
                                        v-model="config.printConfig.pageHeight"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="card">
                              <div class="card-header">Fonte</div>
                              <div class="card-body">
                                <div class="row mx-0 align-items-end">
                                  <div class="col-sm-6 col-12">
                                    <b-form-group
                                      label="Tamanho:"
                                      label-for="fontSize"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="fontSize"
                                        type="number"
                                        v-model="config.printConfig.fontSize"
                                      />
                                    </b-form-group>
                                  </div>
                                  <div class="col-sm-6 col-12 mt-sm-0 mt-3">
                                    <div class="row">
                                      <div class="col-sm-12 col-6">
                                        <div class="row">
                                          <div class="col-auto pr-0">
                                            <b-form-checkbox v-model="config.printConfig.centered"></b-form-checkbox>
                                          </div>
                                          <div class="col pl-0">
                                            <label>Centralizado</label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-sm-12 col-6">
                                        <div class="row">
                                          <div class="col-auto pr-0">
                                            <b-form-checkbox v-model="config.printConfig.bold"></b-form-checkbox>
                                          </div>
                                          <div class="col pl-0">
                                            <label>Negrito</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card mb-0">
                  <div class="card-header">Relatório de lotes separados</div>
                  <div class="card-body">

                    <separated-lots-item-column-fields
                      :config="config"
                      :updateConfig="updateConfig"
                    ></separated-lots-item-column-fields>

                    <separated-lots-order-by
                      :config="config"
                      :updateConfig="updateConfig"
                    ></separated-lots-order-by>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6 col-12">
            <div class="card">
              <div class="card-header">Configurações de Separação</div>
              <div class="card-body">
                <div class="row mx-0 align-items-center">

                  <div class="col-12">
                    <div class="row">
                      <div class="col-auto pr-0 ml-md-0 ml-3">
                        <b-form-checkbox v-model="config.itemsOutConfig.allowItemLotsOutOfSuggestions"></b-form-checkbox>
                      </div>
                      <div class="col pl-0">
                        <label>Permitir a seleção de lotes fora da sugestão</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <b-form-group
                      label="Ordenar pedidos por:"
                    >
                      <multi-list-select
                        :list="orderByFieldOptions"
                        :selectedItems="selectedOrderByFieldOptions"
                        option-value="value"
                        :option-text="'label'"
                        placeholder="Selecione uma ou mais opções"
                        @select="onSelectOrderbyFilterOption"
                      ></multi-list-select>
                    </b-form-group>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12">
            <div class="card">
              <div class="card-header">Configurações da Seleção Manual</div>
              <div class="card-body">

                <b-form-group label="Quantidade padrão">
                  <b-form-radio
                    v-for="option in manualSelectionDefaultQuantityOptions"
                    :key="'manualSelectionConfig.defaultQuantityBehavior_' + option.value"
                    v-model="config.manualSelectionConfig.defaultQuantityBehavior"
                    name="manualSelectionConfig.defaultQuantityBehavior"
                    :value="option.value"
                    class="my-2"
                  >
                    {{option.label}}
                  </b-form-radio>
                </b-form-group>

              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-between mx-0">
          <div class="col-sm-auto col-12 pl-0">
            <b-button variant="danger" @click.prevent="redefinirPadrao()" class="mobile-btn-100 btn-lg">
              Redefinir configurações para o padrão
            </b-button>
          </div>

          <div class="col-sm-auto col-12 mt-sm-0 mt-3 pr-0">
            <b-button variant="success" @click.prevent="salvar()" class="mobile-btn-100 btn-lg">
              Salvar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shared from '@/shared/shared'
import { ModelListSelect } from 'vue-search-select'
import { MultiListSelect } from 'vue-search-select'
import { httpClient } from '@/service'
import SeparatedLotsOrderBy from './separated-lots-order-by'
import SeparatedLotsItemColumnFields from './separated-lots-item-column-fields'

export default {
  name: 'config',
  components: { ModelListSelect, SeparatedLotsOrderBy, SeparatedLotsItemColumnFields, MultiListSelect },

  data() {
    return {
      config: {
        printConfig: {
          model: {},
          width: 100,
          height: 75,
          pageWidth: 100,
          pageHeight: 75,
          fontSize: 21,
          separatedLots: {
            orderBy: [],
            itemColumnFields: []
          }
        },
        itemsOutConfig: {
          allowItemLotsOutOfSuggestions: true,
          orderByField: shared.getOrderByFieldOptions()
        },
        manualSelectionConfig: {
          defaultQuantityBehavior: shared.getManualSelectionDefaultQuantityOptions()[0].value
        }
      },
      lotPrintModels: [],
      locationPrintModels: [],
      selectedOrderByFieldOptions: [],
      orderByFieldOptions: shared.getOrderByFieldOptions(),
      manualSelectionDefaultQuantityOptions: shared.getManualSelectionDefaultQuantityOptions()
    }
  },

  mounted : async function () {
    await this.$store.dispatch('app/changePage', { title:'Configurações', size: 3 });

    let allPrintModels = await shared.getItemLotPrintModels(httpClient, { both: true });

    if (allPrintModels && allPrintModels.length) {
      let defaultIndex = allPrintModels.findIndex(m => !m.value);
      let defaultModel = allPrintModels.splice(defaultIndex, 1)[0];

      this.lotPrintModels = allPrintModels.filter(m => !m.type || m.type === 'itmlot');
      this.locationPrintModels = allPrintModels.filter(m => m.type === 'locati');

      this.lotPrintModels.unshift(defaultModel);
      this.locationPrintModels.unshift(defaultModel);
    }

    this.config = shared.getConfig();

    if (!Array.isArray(this.config.itemsOutConfig.orderByField)) {
      this.config.itemsOutConfig.orderByField = [this.config.itemsOutConfig.orderByField]
    }

    this.selectedOrderByFieldOptions = this.config.itemsOutConfig.orderByField;
  },

  methods: {
    imageWidthChanged() {
      if (this.config && this.config.printConfig) {
        this.config.printConfig.pageWidth = this.config.printConfig.width;
      }
    },

    imageHeightChanged() {
      if (this.config && this.config.printConfig) {
        this.config.printConfig.pageHeight = this.config.printConfig.height;
      }
    },

    salvar() {
      try {
        shared.setLocalStorageObj('config', this.config);
        this.$notify.success('Configurações salvas com sucesso');
      } catch (e) {
        console.log(e);
        this.$notify.textError('Houve um erro ao salvar as configurações');
      }
    },

    redefinirPadrao() {
      this.config = shared.getDefaultConfig();
    },

    updateConfig(config) {
      this.config = config;

      setTimeout(() => {
        this.salvar();
      }, 1);
    },

    printSeparatedLotsConfigItemColumnFieldOptionsChanged(newList) {
      this.config.printConfig.separatedLots.itemColumnFields = newList;
    },

    onSelectOrderbyFilterOption(items) {
      this.selectedOrderByFieldOptions = items;
      this.config.itemsOutConfig.orderByField = items;
    }
  }
}
</script>

<style>
</style>
