<template>
  <div class="card">
    <div class="card-header">Campos da coluna "Item"</div>
    <div class="card-body">

      <div class="row align-items-center">

        <div class="col">
          <b-form-group
            label="Campo"
          >
            <model-list-select
              :list="fieldOptions"
              option-value="field"
              option-text="label"
              v-model="selectedFieldOption"
            />
          </b-form-group>
        </div>

        <div class="col-auto">
          <button type="button" class="btn btn-success mt-2" @click.prevent="addRow">
            <i class="fa fa-plus"></i> Adicionar
          </button>
        </div>

      </div>

      <div v-if="config && config.printConfig && config.printConfig.separatedLots && config.printConfig.separatedLots.itemColumnFields">
        <draggable v-model="config.printConfig.separatedLots.itemColumnFields" group="config.printConfig.separatedLots.itemColumnFields" @end="onRowDrop">
          <div v-for="(itemColumnField, index) in config.printConfig.separatedLots.itemColumnFields"
            :key="'config.printConfig.separatedLots.itemColumnFields_' + index" class="row border py-3 mx-0 cursor-grab" :class="{ 'bg-light': index % 2 == 0 }">

            <div class="col">
              <span class="font-weight-bold">Campo: </span> {{getFieldLabel(itemColumnField)}}
            </div>

            <div class="col-auto">
              <button type="button" class="btn btn-danger btn-sm" @click.prevent="openDeleteModal(index)">
                <i class="fa fa-trash"></i> Remover
              </button>
            </div>

          </div>
        </draggable>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-auto">
          Nenhum campo adicionado
        </div>
      </div>

      <confirmation-modal
        id="separated-lots-item_column-fields-delete-confirmation-modal"
        msg="Tem certeza de que deseja remover esse campo?"
        :ok="deleteItem"
      ></confirmation-modal>

    </div>
  </div>
</template>

<script>
import shared from '@/shared/shared'
import { ModelListSelect } from 'vue-search-select'
import draggable from 'vuedraggable'
import ConfirmationModal from '@/components/common/confirmation-modal'

export default {
  components: { ModelListSelect, draggable, ConfirmationModal },

  props: {
    config: {
      type: Object,
      require
    },
    updateConfig: {
      type: Function,
      require
    }
  },

  data() {
    return {
      fieldOptions: shared.getPrintSeparatedLotsConfigItemColumnFieldOptions(),
      selectedFieldOption: {},
      deleteIndex: -1
    }
  },

  mounted() {
    this.resetForm();
  },

  methods: {
    resetForm() {
      this.selectedFieldOption = {};
    },

    getFieldLabel(field) {
      if (field) {
        let temp = this.fieldOptions.find(fo => fo.field === field);

        if (temp) {
          return temp.label;
        }
      }

      return '';
    },

    onRowDrop() {
      this.updateConfig(this.config);
    },

    addRow() {
      try {
        if (!this.selectedFieldOption || !this.selectedFieldOption.field) {
          this.$notify.textError('Selecione o campo');
          return;
        }

        if (!this.config.printConfig.separatedLots.itemColumnFields) {
          this.config.printConfig.separatedLots.itemColumnFields = [];
        }

        if (this.config.printConfig.separatedLots.itemColumnFields.includes(this.selectedFieldOption.field)) {
          this.$notify.textError('Esse campo já foi adicionado');
          return;
        }

        this.config.printConfig.separatedLots.itemColumnFields.push(this.selectedFieldOption.field);
        this.$notify.success('Campo adicionado com sucesso');
        this.resetForm();
        this.updateConfig(this.config);
      } catch (e) {
        console.error(e);
        this.$notify.textError('Ocorreu um erro: ' + e);
      }
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show('separated-lots-item_column-fields-delete-confirmation-modal');
    },

    deleteItem() {
      this.$notify.success('Campo removido com sucesso');
      this.config.printConfig.separatedLots.itemColumnFields.splice(this.deleteIndex, 1);
      this.deleteIndex = -1;
      this.updateConfig(this.config);
    },
  }
}
</script>
